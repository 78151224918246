// export const url = "https://factory.ezxdemo.com";
// export const socketUrl = 'http://127.0.0.1:4001';

export const alterNateImage = require('./assests/marketplace-logo.png');

//Production Url

// export const socketUrl = 'https://socket.efunder.ai';
// export const url = "https://java.efunder.ai";

//Staging Url

// export const url = 'https://javastage.efunder.ai';
// export const socketUrl = 'https://socketstage.efunder.ai';

//Dev Url

export const url = 'https://javaapidev.efunder.ai';
export const socketUrl = 'https://socketdev.efunder.ai';

// Static Values Constants
export const clientTypeDef = "Client Type Definitions"
export const clientMoneyRules = "Applicable Client Money Rules"
export const termNcon = "Terms and Conditions"
export const disclaimer = "Disclaimer"
export const helpCenterCeo = "Help Center CEO"
export const helpCenterCompliance = "Help Center Compliance/MLRO Officer"
export const privacyPolicy = "Privacy Policy"
export const isAllowSessionTime = 14 * 60 * 1000; // 14 मिनट (14 * 60 * 1000 मिलीसेकंड)
